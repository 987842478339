import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Parse from "html-react-parser"
const Header = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          generalSettings {
            title
          }
        }
      }
    `
  )
  const defaultTitle = wp.generalSettings?.title
  const [isActive, setisActive] = React.useState(false)
  return (
    <header>
      <nav className="navbar is-fixed-top">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <StaticImage
              src="../../content/assets/header-logo.svg"
              alt={Parse(defaultTitle)}
              placeholder="blurred"
              layout="fixed"
              width={160}
              height={52}
            />
          </Link>
          <div
            className={`navbar-burger ${isActive ? "is-active" : ""}`}
            data-target="globalNavigation"
            onClick={() => {
              setisActive(!isActive)
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div
          id="globalNavigation"
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
        >
          <div className="navbar-end">
            <Link className="navbar-item" to="/">
              ホーム
            </Link>
            {/* <Link className="navbar-item" to="/member/">
              メンバー
            </Link> */}
            <div className="navbar-item has-dropdown is-hoverable">
              <Link className="navbar-link" to="/company/">
                企業情報
              </Link>
              <div className="navbar-dropdown is-boxed">
                <Link className="navbar-item" to="/philosophy/">
                  企業理念
                </Link>
              </div>
            </div>
            <Link className="navbar-item" to="/service/">
              サービス
            </Link>
            <div className="navbar-item has-dropdown is-hoverable">
              <Link className="navbar-link" to="/recruit/">
                採用情報
              </Link>
              <div className="navbar-dropdown is-boxed">
                <Link className="navbar-item" to="/environments/">
                  勤務環境／ワークサポート
                </Link>
              </div>
            </div>
            {/* <a
              className="navbar-item is-hidden-desktop-only"
              to="/customers/"
            >
              お客様紹介
            </a> */}
            <a
              className="navbar-item is-hidden-desktop-only"
              href="https://itxs2021.xsrv.jp/news/"
            >
              ニュース
            </a>
            <a
              className="navbar-item is-hidden-desktop-only"
              href="https://itxs2021.xsrv.jp/blog/"
            >
              ブログ
            </a>
            <Link className="navbar-item" to="/faq/">
              よくあるご質問
            </Link>
            <Link className="navbar-item" to="/contact/">
              お問い合わせ
            </Link>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
