/**
 * Head component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"
// let striptags = require("striptags")
// function sumarrize(html) {
//   const metaDescription = striptags(html).replace(/\r?\n/g, "").trim()
//   return metaDescription.length <= 120
//     ? metaDescription
//     : metaDescription.slice(0, 120) + "..."
// }

const Head = ({ description, lang, meta, title, image }) => {
  const { pathname } = useLocation()

  const { site, wp, wpUser, ogpImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            facebookAppId
            twitterUsername
          }
        }

        wp {
          generalSettings {
            title
            description
          }
        }

        # if there's more than one user this would need to be filtered to the main user
        wpUser {
          twitter: name
        }

        ogpImage: file(relativePath: { eq: "ogp-image.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    `
  )

  const metaDescription = description || wp.generalSettings?.description
  // metaDescription = sumarrize(metaDescription)
  const defaultTitle = wp.generalSettings?.title
  const metaImage = `${site.siteMetadata.siteUrl}${
    image || getSrc(ogpImage?.childImageSharp)
  }`
  const metaUrl = `${site.siteMetadata.siteUrl}${pathname}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      image={image}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitterUsername,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `fb:app_id`,
          content: site.siteMetadata.facebookAppId,
        },
      ].concat(meta)}
    />
  )
}

Head.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
}

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Head
