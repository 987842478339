import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Parse from "html-react-parser"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebook,
  faInstagramSquare,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import * as styles from "./footer.module.scss"

const Footer = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          generalSettings {
            title
          }
        }
      }
    `
  )
  const defaultTitle = wp.generalSettings?.title
  return (
    <footer className={"footer " + styles.footer}>
      <div className="container">
        <div className="is-flex is-justify-content-space-between">
          <div className={styles.philosophy}>
            <div className={styles.title}>
              ITを通して、社会生活を豊かにする。
            </div>
            <div className={styles.subtitle}>
              Enriching social life through IT.
            </div>
          </div>
          <div className="footer-logo">
            <Link to="/">
              <StaticImage
                src="../../content/assets/footer-logo.svg"
                alt={Parse(defaultTitle)}
                placeholder="blurred"
                layout="fixed"
                width={92}
                height={128}
              />
            </Link>
          </div>
        </div>
        <div className="columns is-variable is-5">
          <div className="column is-two-fifths">
            <div className="block">
              <ul className={styles.menu}>
                <li>
                  <Link className="anchor" to="/">
                    ホーム
                  </Link>
                </li>
                {/* <li>
                  <Link className="anchor"to="/member/">メンバー</Link>
                </li> */}
                <li>
                  <Link className="anchor" to="/company/">
                    企業情報
                  </Link>
                </li>
                <li>
                  <Link className="anchor" to="/philosophy/">
                    企業理念
                  </Link>
                </li>
                <li>
                  <Link className="anchor" to="/service/">
                    サービス
                  </Link>
                </li>
                <li>
                  <Link className="anchor" to="/recruit/">
                    採用情報
                  </Link>
                </li>
                <li>
                  <Link className="anchor" to="/environments/">
                    勤務環境／ワークサポート
                  </Link>
                </li>
                {/* <li>
                  <a className="anchor" to="/customers/">
                    お客様紹介
                  </a>
                </li> */}
                <li>
                  <a className="anchor" href="https://itxs2021.xsrv.jp/news/">
                    ニュース
                  </a>
                </li>
                <li>
                  <a className="anchor" href="https://itxs2021.xsrv.jp/blog/">
                    ブログ
                  </a>
                </li>
                <li>
                  <Link className="anchor" to="/faq/">
                    よくあるご質問
                  </Link>
                </li>
                <li>
                  <Link className="anchor" to="/contact/">
                    お問い合わせ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="block">
          <ul className={styles.submenu}>
            <li>
              <Link to="/privacy-policy/">プライバシーポリシー</Link>
            </li>
            <li>
              <Link to="/security">情報セキュリティ基本方針</Link>
            </li>
          </ul>
        </div>
        <div className="columns is-variable is-5">
          <div className="column">
            <nav className="level">
              <div className="level-left">
                <div className="level-item">Follow us</div>
                <div className="level-item">
                  <span className="icon-text">
                    <Link
                      className="icon"
                      to="https://twitter.com/ITxS_JP"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </Link>
                    <Link
                      className="icon"
                      to="https://www.facebook.com/itxs.jp/"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </Link>
                    <Link
                      className="icon"
                      to="https://www.instagram.com/itxs_jp/"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faInstagramSquare} />
                    </Link>
                    <Link
                      className="icon"
                      to="https://www.youtube.com/channel/UClsN3h5IuTwzAtfanzwYU7w"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </Link>
                  </span>
                </div>
              </div>
            </nav>
          </div>
          <div className="column">
            <div className={styles.copyright}>
              <small>&copy; ITxS Co., Ltd.</small>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contact}>
        <Link to="/contact/" className="button is-small is-outlined is-rounded">
          お問い合わせ
        </Link>
      </div>
    </footer>
  )
}
export default Footer
