// extracted by mini-css-extract-plugin
export var content = "footer-module--content--3jIbB";
export var hasMaxWidth200 = "footer-module--has-max-width-200--2X-XY";
export var hasMaxWidth600 = "footer-module--has-max-width-600--sg6-_";
export var footer = "footer-module--footer--3L5Rb";
export var philosophy = "footer-module--philosophy--1QbTf";
export var title = "footer-module--title--25Lol";
export var subtitle = "footer-module--subtitle--1kA3C";
export var menu = "footer-module--menu--2SfYd";
export var submenu = "footer-module--submenu--3E0J_";
export var copyright = "footer-module--copyright--3WaIr";
export var contact = "footer-module--contact--1-WMo";